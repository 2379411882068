'use strict';

window.addEventListener('load', function () {
  swiperSlider();

/*swiper slider*/
function swiperSlider() {
 // == procuct slider
 rundrugSlider();
 function rundrugSlider(){
  var drugSlider = new Swiper ('.drugSlider', {
    slidesPerView:3,
    slidesPerGroup:3,
    speed: 1500,
  //  autoplay:5000,
    initialSlide:1,
  //  loop: true,
    nextButton: '.drug-slider-wrap .arr-next',
    prevButton: '.drug-slider-wrap .arr-prev',   
   // pagination:'.swiper-pagination',
   // paginationClickable: true,      
    onTransitionEnd: function(sw){},
     breakpoints: {
        1240: {
          slidesPerView: 2,
           slidesPerGroup:2,
        },        
        800: {
          slidesPerView: 1,
           slidesPerGroup:1,
        },
      }
  });
 };
}





});

