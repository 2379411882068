'use strict';

window.addEventListener('load', function () {
	// load();

 hackAnhor() 
});

	function hackAnhor() {
		var top = $('.header__nav');
		var  botom = $('.footer-nav__item');
		var grugs = $('.drugSlider');
		var topElem = 0;
		var location = window.location.href;

		if(grugs.length) {
			topElem = grugs.offset().top - 60;
		}

		top.on('click', 'a', seekAnhor );
		botom.on('click', 'a', seekAnhor );
	
		function seekAnhor(e) {		
			var target = e.target;
			var text = $(target).text();
			if(text.indexOf('Препарати') !== -1 || text.indexOf('Препараты') !== -1 || text.indexOf('Preparations') !== -1 ) {
				e.preventDefault();

				if(!grugs.length){
					window.location = location + "/#drugs";
					return;
				};	

				$('html, body').stop().animate({'scrollTop':topElem},1000);
			}
		
		}

	}

//load content 
function load(){
	document.body.classList.add('show');
	var preloader = document.querySelector('#preloader');

	preloader.classList.add('hide');
	setTimeout(function(){
		preloader.remove();
	}, 2000);
}
