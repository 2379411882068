'use strict';

window.addEventListener('load', function () {
  openCloseMenu();
});

// ======= open - close menu ========
function openCloseMenu(){
  var btn = document.getElementById('burger');
  var mobileNav = document.getElementById('mobileNav');
  if(!mobileNav) return;
 // var mobileNavClose = mobileNav.querySelector('.close');

  btn.addEventListener('click', function(){
    this.classList.toggle('open');
    document.body.classList.toggle('openNav');
  });  
  mobileNav.addEventListener('click', function(e){
    if(e.target.tagName === 'A') {
        document.body.classList.remove('openNav');
        btn.classList.remove('open');
    }
  }); 

  document.addEventListener('keydown', function(e){
    if(e.keyCode === 27) {
      document.body.classList.remove('openNav');
      btn.classList.remove('open');
    }
  });

};



