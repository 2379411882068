'use strict';

window.addEventListener('load', function () {
  scrollAnchors('.anchorBtn', 70);
});

// ======= скроллинг к якорю и активное меню ========
  // ===== якоря
    function scrollAnchors(btn, offset, callback){
      var hash = window.location.hash;
      var anhor = hash.replace(/#/, ''); 
      var reqElem = $('[data-anchor= "'+ anhor +'"]');

      if(reqElem.length) {
        var topElem = reqElem.offset().top - offset;

        $('html, body').scrollTop(topElem);  
      }

      if (!btn.length) return;

      $(btn).on('click', function(e){
        e.preventDefault();

        var btn = $(this);

        if(this.tagName == 'A') {
          var hash = btn.attr('href'); 
          var anhor = hash.replace(/#/, ''); 
        } else {
          var anhor = btn.attr('data-link'); 
        };
        
        var reqElem = $('[data-anchor= "'+ anhor +'"]');
        var topElem = reqElem.offset().top - offset;

        $('html, body').stop().animate({'scrollTop':topElem},1000,  function(){
          if (callback) {
            callback();
          }
        });
        window.location.hash = anhor;
      });

    };

